import waves from './waves/index'

const directives = {
  waves
}

export default {
  install(app) {
    Object.keys(directives).forEach(key => {
      app.directive(key, directives[key])
    })
  }
} 