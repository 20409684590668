<template>
  <el-container class="layout-container">
    <el-aside width="200px">
      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical"
        :collapse="isCollapse"
        background-color="#304156"
        text-color="#bfcbd9"
        active-text-color="#409EFF"
      >
        <el-menu-item index="/dashboard" @click="handleMenuClick('/dashboard')">
          <el-icon><House /></el-icon>
          <span>首页</span>
        </el-menu-item>
        
        <el-sub-menu index="/wechat">
          <template #title>
            <el-icon><ChatDotRound /></el-icon>
            <span>微信管理</span>
          </template>
          <el-menu-item index="/wechat/config" @click="handleMenuClick('/wechat/config')">微信配置</el-menu-item>
          <el-menu-item index="/wechat/menu" @click="handleMenuClick('/wechat/menu')">菜单管理</el-menu-item>
          <el-menu-item index="/wechat/followers" @click="handleMenuClick('/wechat/followers')">关注者管理</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="/ai">
          <template #title>
            <el-icon><Monitor /></el-icon>
            <span>AI管理</span>
          </template>
          <el-menu-item index="/ai/config" @click="handleMenuClick('/ai/config')">AI配置</el-menu-item>
          <el-menu-item index="/ai/chat" @click="handleMenuClick('/ai/chat')">对话历史</el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-aside>
    
    <el-container>
      <el-header>
        <div class="header-left">
          <el-icon class="toggle-sidebar" @click="toggleSidebar">
            <Fold v-if="!isCollapse" />
            <Expand v-else />
          </el-icon>
          <el-button type="primary" size="small" @click="testConsole" style="margin-left: 10px;">
            测试控制台
          </el-button>
        </div>
        <div class="header-right">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ userInfo.nickname || userInfo.username || '未登录' }}
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="profile">个人信息</el-dropdown-item>
                <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      
      <el-main>
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { defineComponent, ref, computed, watch, version, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { House, ChatDotRound, Monitor, Fold, Expand, ArrowDown } from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import { getUserInfo, logout } from '@/api/auth'
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  name: 'MainLayout',
  components: {
    House,
    ChatDotRound,
    Monitor,
    Fold,
    Expand,
    ArrowDown
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const isCollapse = ref(false)
    const userInfo = ref({
      id: '',
      username: '',
      nickname: '',
      avatar: ''
    })

    const activeMenu = computed(() => {
      console.log('当前路由路径:', route.path)
      return route.path
    })

    // 监听路由变化
    watch(() => route.path, (newPath, oldPath) => {
      console.log('路由变化:', {
        from: oldPath,
        to: newPath,
        fullRoute: route
      })
    }, { immediate: true })

    // 获取用户信息
    const fetchUserInfo = async () => {
      try {
        const res = await getUserInfo()
        if (res.code === 200) {
          userInfo.value = res.data
          console.log('获取用户信息成功:', userInfo.value)
        } else {
          console.error('获取用户信息失败:', res)
        }
      } catch (error) {
        console.error('获取用户信息错误:', error)
        // 如果获取用户信息失败，可能是token无效，跳转到登录页
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token')
          router.push('/login')
        }
      }
    }

    // 处理下拉菜单命令
    const handleCommand = (command) => {
      if (command === 'logout') {
        handleLogout()
      } else if (command === 'profile') {
        // 跳转到个人信息页
        console.log('跳转到个人信息页')
      }
    }

    // 退出登录
    const handleLogout = () => {
      ElMessageBox.confirm('确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await logout()
          localStorage.removeItem('token')
          ElMessage.success('退出登录成功')
          router.push('/login')
        } catch (error) {
          console.error('退出登录错误:', error)
          // 即使请求失败，也清除本地token并跳转
          localStorage.removeItem('token')
          router.push('/login')
        }
      }).catch(() => {
        // 取消退出
      })
    }

    const handleMenuClick = (path) => {
      console.log('菜单点击:', path)
      router.push(path).catch(err => {
        console.error('路由跳转错误:', err)
      })
    }

    const toggleSidebar = () => {
      isCollapse.value = !isCollapse.value
    }

    // 添加测试函数
    const testConsole = () => {
      console.log('=== 测试控制台输出 ===')
      console.log('1. 基础信息:')
      console.log('- 当前时间:', new Date().toLocaleString())
      console.log('- 浏览器信息:', navigator.userAgent)
      
      console.log('\n2. 路由信息:')
      console.log('- 当前路径:', route.path)
      console.log('- 路由名称:', route.name)
      console.log('- 路由参数:', route.params)
      console.log('- 查询参数:', route.query)
      
      console.log('\n3. 组件信息:')
      console.log('- Element Plus版本:', ElementPlus.version)
      console.log('- Vue版本:', version)
      
      console.log('\n4. 系统状态:')
      console.log('- 侧边栏状态:', isCollapse.value ? '折叠' : '展开')
      console.log('- 当前激活菜单:', activeMenu.value)
      console.log('- 用户信息:', userInfo.value)
      
      console.log('\n5. 环境信息:')
      console.log('- 运行环境:', process.env.NODE_ENV)
      console.log('- API地址:', process.env.VUE_APP_BASE_API || '/api')
      
      console.log('=== 测试完成 ===')
    }

    // 初始化时获取用户信息
    onMounted(() => {
      console.log('Layout组件加载，检查token并获取用户信息')
      const token = localStorage.getItem('token')
      if (token) {
        fetchUserInfo()
      } else {
        console.warn('未找到token，跳转到登录页')
        router.push('/login')
      }
    })

    return {
      isCollapse,
      activeMenu,
      userInfo,
      toggleSidebar,
      handleMenuClick,
      handleCommand,
      testConsole
    }
  }
})
</script>

<style scoped>
.layout-container {
  height: 100vh;
}

.el-aside {
  background-color: #304156;
  transition: width 0.3s;
}

.el-menu-vertical {
  height: 100%;
  border-right: none;
}

.el-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
}

.header-left {
  display: flex;
  align-items: center;
}

.toggle-sidebar {
  font-size: 20px;
  cursor: pointer;
  padding: 0 15px;
}

.header-right {
  padding-right: 20px;
}

.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.el-main {
  background-color: #f0f2f5;
  padding: 20px;
}
</style> 