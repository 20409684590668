import './waves.css'

export default {
  mounted(el, binding) {
    el.classList.add('waves-effect')
    const customClass = binding.value || ''
    if (customClass) {
      el.classList.add(customClass)
    }
    
    el.addEventListener('click', e => {
      const rect = el.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top
      const ripples = document.createElement('span')
      ripples.className = 'waves-ripple'
      ripples.style.left = x + 'px'
      ripples.style.top = y + 'px'
      el.appendChild(ripples)
      
      setTimeout(() => {
        ripples.remove()
      }, 1000)
    })
  }
} 