import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import directives from './directive' // 导入指令

console.log('开始初始化应用')

const app = createApp(App)

// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
  console.log('注册图标组件:', key)
}

app.use(ElementPlus)
app.use(store)
app.use(router)
app.use(directives) // 注册指令

// 添加全局错误处理
app.config.errorHandler = (err, vm, info) => {
  console.error('Vue错误:', err)
  console.error('错误信息:', info)
  console.error('组件:', vm)
}

// 添加路由错误处理
router.onError((error) => {
  console.error('路由错误:', error)
})

// 添加全局未捕获Promise错误处理
window.addEventListener('unhandledrejection', event => {
  console.error('未处理的Promise错误:', event.reason)
})

// 抑制ResizeObserver Loop错误
const resizeObserverError = /ResizeObserver loop completed with undelivered notifications/;
window.addEventListener('error', (event) => {
  if (resizeObserverError.test(event.message)) {
    event.stopImmediatePropagation();
  }
});

app.mount('#app')
console.log('应用挂载完成')
